// action types
export const UPDATE_APP_VARIABLES = 'UPDATE_APP_VARIABLES';

// actions
export const updateAppVariables = (payload) => ({
  type: UPDATE_APP_VARIABLES,
  payload
});

// reducer
const initialState = {
  locale: 'en-in',
  languageId: 'en-in'
};

const appVariablesReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_APP_VARIABLES:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
  // 移除了多余的 return state; 语句
};

export default appVariablesReducer;
