import axios from '@tw/shared-ui-components/lib/utils/axios_interceptor';

const apiUrl = process.env.PDM_BFF_API || "http://localhost:3002";

export const SET_ANNOUNCEMENT = "SET_ANNOUNCEMENT";

export const setAnnouncement = (announcement) => ({
  type: SET_ANNOUNCEMENT,
  payload: announcement,
});

export const fetchAnnouncement = (languageId) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${apiUrl}/announcement?languageId=${languageId}`
    );
    dispatch(setAnnouncement(res.data));
  } catch (err) {
    console.log('announcement-reducer.js', err);
  }
};

const initialState = { /* 初始状态的定义 */ };

const announcementReducer = (state = initialState, action) => {
  const { type, payload } = action; // 从 action 对象中解构出 type 和 payload
  switch (type) {
    case SET_ANNOUNCEMENT:
      // 根据实际情况，这里可能需要更复杂的逻辑来更新 state
      // 例如：return { ...state, announcement: payload };
      return payload; // 如果 payload 是整个新的 state，则直接返回
    default:
      return state;
  }
};
export default announcementReducer;
